<template>
    <div>
        <DataTable
            :value="orgsTypes"
            :paginator="true"
            class="p-datatable-customers p-datatable-striped"
            :class="{ showFilters: showFilters }"
            :rowsPerPageOptions="rowsPerPageOptions"
            :rows="20"
            :scrollable="true"
            scroll-height="calc(100vh - 350px)"
            :totalRecords="totalRecords"
            dataKey="id"
            selectionMode="single"
            :filters="filters"
            stripedRows
            @row-dblclick='rowDblClickHandler'
        >
            <!--  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown":currentPageReportTemplate="`Показана с {first} по {last} всего ${totalRecords}`" :lazy="true"
            @page="paginationChange($event)"
            @sort="onSort($event)" -->
            <template #header>
                <div class="table-header p-d-flex p-jc-md-between p-flex-wrap">
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center">
                        <h5 class="p-d-inline p-mb-0 p-pt-0">Типы организации</h5>
                    </div>
                    <div class="p-col-6 p-p-0 p-d-flex p-ai-center p-mt-2"></div>
                    <!-- <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-mt-2">
                        <Button icon="pi pi-filter" class="p-button-rounded p-button-outlined" @click="showFilters = !showFilters" />
                    </div> -->
                    <div class="p-col-6 p-p-0 p-d-flex p-ai-center">
                        <div class="p-inputgroup p-p-0 p-d-flex">
                            <span class="p-float-label">
                                <InputText type="text" v-model="filters['global']" placeholder="Поиск" />
                            </span>
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-search"></i>
                            </span>
                        </div>
                        <div v-if='canEdit' class="p-p-0 p-new-btn">
                            <Button @click="openAddCollectionDialog('Добавить')" class="p-button p-ml-3"> Добавить </Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty> Типов не найдено.</template>
            <template #loading> Загрузка типов организаций. Пожалуйста, подождите. </template>
            <Column header="Наименование" header-class="type-name-header table-column" body-class="type-name-body" body-style="max-height: 44px;" field="name" :sortable="true">
                <template #body="slotProps">
                    <div class="p-text-nowrap p-text-truncate">{{ slotProps.data.name }}</div>
                </template>
                <template #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['name']" class="p-column-filter" placeholder="Наименование" />
                </template>
            </Column>
            <Column header="Статус" header-class="type-status-header table-column" body-class="type-status-body" body-style="max-height: 44px;" field="status" :sortable="true">
                <template #body="slotProps">
                    <div class="status" :class="{ activeStatus: slotProps.data.status == 'активная', noActiveStatus: slotProps.data.status == 'неактивная' }">{{ slotProps.data.status }}</div>
                </template>
                <template #filter>
                    <Dropdown v-show="showFilters" v-model="filters['status']" :options="statuses" placeholder="Статус" class="p-column-filter" :showClear="true">
                        <template #option="slotProps">
                            <span :class="'customer-badge status-' + slotProps.option">{{ slotProps.option }}</span>
                        </template>
                    </Dropdown>
                </template>
            </Column>
            <Column header="" body-style="position: relative; width: 52px; text-align: center;max-height: 44px;" header-style="width: 52px;">
                <template v-if='accessibleItems(listMenuItems).length' #body="slotProps">
                    <button class="p-panel-header-icon p-link p-mr-2" @click.stop="showTypeMenu($event, slotProps.data.id, slotProps.data.active)">
                        <span class="pi pi-ellipsis-h"></span>
                    </button>
                    <Menu id="list_menu" class="redLastListElement" :ref="`listMenu${slotProps.data.id}`" :model="accessibleItems(listMenuItems)" :popup="true" />
                </template>

                <template #header>
                    <Button icon="pi pi-filter " :class="filterClasses" class="p-button-rounded p-button-outlined filter-btn" @click="showFilters = !showFilters" />
                </template>
            </Column>
            <template #paginatorLeft>
                <div class="p-col-6"></div>
            </template>
        </DataTable>
        <!-- <Dialog :visible.sync="deletePeriodShow" position="center" :modal="true" :closeOnEscape="true" :closable="true" style="width: 400px" class="delDialog">
            <template #header>
                <h3 class="p-mb-0 p-pl-2 customTitle">Удалить подчиненность</h3>
            </template>
            <div class="p-dialog-content p-ai-center p-d-flex p-pl-2">
                 <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"></i>
                <span>Подтвердите, пожалуйста, что вы удаляете подчиненность. Восстановить его не получится. </span>
            </div>

            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button p-button-danger" @click="removePeriod(currentPeriodId)">
                        <span class="p-button-label">Удалить</span>
                    </Button>
                    <Button class="p-button p-button-outlined p-button-secondary" type="button" @click="hideDeletePopUp">
                        <span class="p-button-label">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog> -->

        <Dialog :visible.sync="addCollectionElementDialog" position="center" :modal="true" :closeOnEscape="true" :closable="true" style="width: 500px" class="customDialog">
            <template #header>
                <h3 class="p-mb-0 customTitle p-px-2">{{ dialogMode == 'Добавить' ? 'Добавить тип организации' : 'Редактировать тип организации' }}</h3>
            </template>

            <div class="p-d-flex p-flex-column p-field p-col-12 p-py-0 p-mb-0 p-px-2">
                <label for="newOrgsTagName">Наименование*</label>
                <InputText id="system" type="text" v-model="v$.newCollectionElementName.$model"
                           placeholder="Введите тип организации"> </InputText>
                <small v-show="submitted" v-for="error in showErrors(v$.newCollectionElementName)" :key="error.$uid" class="p-error">
                    <small>{{ error.$message }}</small><br>
                </small>
            </div>
            <div v-if="dialogMode == 'Редактировать'" class="p-field-checkbox p-d-flex p-ai-center p-field p-col-12 p-pt-3 p-pb-0 p-mb-0 p-px-2">
                <Checkbox id="binary" v-model="currentTypeStatus" :binary="true" />
                <label for="binary" class="p-my-0 p-mr-0">Активная</label>
            </div>

            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button" v-if="dialogMode == 'Добавить'" type="button" @click="createType" :disabled="newCollectionElementName.length < 3"> <span class="p-button-label">Добавить </span> <span class="p-ink"></span> </Button>
                    <Button class="p-button" v-else type="button" @click="patchType" :disabled="newCollectionElementName.length < 3"> <span class="p-button-label">Изменить </span> <span class="p-ink"></span> </Button>

                    <Button class="p-button p-button-outlined" @click="closeDialog">
                        <span class="p-button-label black">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { createOrgsType, getOrgsTypes, patchOrgsType } from '@/api/organization/orgsTypes';
import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { USER_PERMISSIONS_MAP } from '@/constants/common';

const { organizationWriteAll } = USER_PERMISSIONS_MAP

export default {
    name: 'type',
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    emits: ['loadingChange'],
    mixins: [requestToastHandler],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            filters: {},
            showFilters: false,
            currentPage: 1,
            rowCount: 20,
            totalRecords: 0,
            deletePeriodShow: false,
            dialogMode: '',
            orgsTypes: [],
            currentTypeId: null,
            currentTypeStatus: null,
            addCollectionElementDialog: false,
            newCollectionElementName: '',
            addCollectionElementDialogLoading: false,
            listMenuItems: [
                {
                    label: 'Редактировать',
                    icon: 'pi pi-pencil',
                    command: () => {
                        this.openAddCollectionDialog('Редактировать');
                    },
                    permissionAccess: [organizationWriteAll]
                },
                // {
                //     label: 'Удалить',
                //     icon: 'pi pi-trash',
                //     disabled: true,
                //     command: () => {
                //         // this.showDeletePopUp(this.currentPeriodId);
                //     },
                // },
            ],
            statuses: ['активная', 'неактивная'],
            submitted: false,
        };
    },
    validations() {
        return {
            newCollectionElementName: {
                required: {
                    ...required,
                    $message: 'Поле обязательно к заполнению'
                }
            }
        }
    },
    methods: {
        rowDblClickHandler(event) {
            const { data: { id, active, name } } = event
            this.currentTypeId = id;
            this.currentTypeStatus = active;
            this.newCollectionElementName = name;
            this.openAddCollectionDialog('Редактировать')
        },
        openAddCollectionDialog(mode) {
            if (!this.canEdit) return
            this.submitted = false;
            this.dialogMode = mode;
            if (mode == 'Добавить') this.newCollectionElementName = '';
            this.addCollectionElementDialog = true;
        },
        closeDialog() {
            this.dialogMode = '';
            this.newCollectionElementName = '';
            this.currentTypeId = null;
            this.currentTypeStatus = null;
            this.addCollectionElementDialog = false;
        },
        showTypeMenu(event, TypeId, active) {
            if (TypeId !== this.currentTypeId) {
                Object.keys(this.$refs).map((key) => {
                    this.$refs[key] && this.$refs[key].hide(event);
                });
            }
            this.currentTypeId = TypeId;
            this.currentTypeStatus = active;
            let currentType = this.orgsTypes.find((types) => types.id == TypeId);

            this.newCollectionElementName = currentType.name;
            this.$refs[`listMenu${TypeId}`].toggle(event);
        },
        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        },
        async getData() {
            this.$emit('loadingChange', true);
            try {
                let result = await getOrgsTypes();

                this.orgsTypes = result.types.map((el) => ({
                    ...el,
                    status: el.active ? 'активная' : 'неактивная',
                    loading: false,
                }));
                this.totalRecords = result.meta.pagination.total;

                this.orgsTypes = this.sortArray(this.orgsTypes);
            } catch (error) {
                this.$requestError(error.message);
            }

            this.$emit('loadingChange', false);
        },
        async createType() {
            this.submitted = true;

            if (!this.v$.$invalid) {
                this.addCollectionElementDialog = false;
                let dataToCreate = {};

                dataToCreate.type = 'organization-type';
                dataToCreate.id = null;
                dataToCreate.attributes = { active: true, name: this.newCollectionElementName };
                try {
                    this.$emit('loadingChange', true);
                    const result = await createOrgsType({
                        data: {
                            ...dataToCreate,
                        },
                    });

                    if (result) {
                        // this.$toast.add({
                        //     severity: 'success',
                        //     summary: 'Новая тип организации успешно создан',
                        //     life: 2000,
                        // });
                        this.closeDialog();
                        this.getData();
                        return;
                    }
                } catch (error) {
                    this.$requestError(error.message);
                    this.$emit('loadingChange', false);
                }
            }
        },
        async patchType() {
            this.submitted = true;

            if (!this.v$.$invalid) {
                this.addCollectionElementDialog = false;
                this.$emit('loadingChange', true);
                let dataToPatch = {
                    data: {
                        type: 'organization-structure',
                        id: this.currentTypeId,
                        attributes: {
                            active: this.currentTypeStatus,
                            name: this.newCollectionElementName,
                        },
                    },
                };
                try {
                    const result = await patchOrgsType(dataToPatch, this.currentTypeId);
                    this.getData();

                    return result;
                } catch (error) {
                    this.$requestError(error.message);
                }
            }
        },
        sortArray(array) {
            return array.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (a.name < b.name) return -1;
                return 0;
            });
        },
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        ...mapGetters('auth', [
           'userPermissionsObject',
           'accessibleItems'
        ]),
        filterClasses() {
            return this.showFilters ? 'mainBackground' : '';
        },
        canEdit() {
            return [organizationWriteAll].some(p => this.userPermissionsObject[p])
        }
    },

    async mounted() {
        await this.getData();
    },
};
</script>

<style lang='scss' scoped>
.status {
    border-radius: 4px;
    padding: 4px;
    max-width: 64px;
    color: #272727;
    font-size: 13px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    // margin: 0 auto;
}
.activeStatus {
    background-color: #a1e6c3;
}
.noActiveStatus {
    background-color: #d7d7d7;
    max-width: 78px;
}

.customTitle {
    font-size: 17px;
}
::v-deep .p-datatable-header {
    padding-top: 0px;
    border-top: none;
    padding-left: 0;
    .p-inputtext:enabled:hover {
        border-color: #eaeaea;
    }
}
::v-deep .p-datatable {
    .p-datatable-tbody > tr > td {
        border: none;
    }
}
// ::v-deep .redLastListElement {
//     top: 10px !important;
//     right: 10px !important;
//     left: -9% !important;
//     li:last-child {
//         span {
//             color: red !important;
//         }
//     }
// }
::v-deep .customDialog {
    width: 480px;
}
::v-deep .p-inputgroup-addon {
    background: inherit;
    border-left: none;
}
::v-deep .p-inputgroup {
    .p-float-label {
        input {
            border-right: none;
        }
    }
}
::v-deep .p-datatable-thead {
    position: relative;

    // .mainBackground {
    //     background-color: #388e3c;
    //     color: white;
    //     outline: none;
    //     border: none;
    // }

    tr:last-child {
        th:last-child {
            text-align: right;
        }
    }
    .p-filter-column {
        padding: 0;
        background-color: #fff !important;
        input {
            min-width: 98%;
            max-width: 98%;
            margin: 5px;
        }
        .p-dropdown-clearable {
            max-width: 98%;
        }
    }
}
.p-datatable {
    &-customers {
        ::v-deep {
            .table-column {
                padding-right: 3.411vw;
            }

            .type-name {
                &-header {
                    width: 57.1875vw;
                }
                &-body {
                    width: 57.1875vw;
                }
            }

            .type-status {
                &-header {
                    width: 16.484375vw;
                }
                &-body {
                    width: 16.484375vw;
                    text-align: left;
                }
            }

            .p-filter-column {
                padding: 4px 0 4px 1rem;
                background-color: #fff !important;
                input {
                    min-width: 98%;
                    max-width: 98%;
                }
            }

            .p-datatable-header {
                padding-left: 0;
                padding-top: 0;
                border: unset;
            }
            .filter-btn {
                width: 24px;
                height: 24px;
                background: white;
            }
            tr {
                .redLastListElement {
                    position: fixed !important;
                    display: block !important;
                    z-index: 1042;
                    left: unset !important;
                    transform: translateX(-100%);
                }
            }
        }
    }
}
.p-new-btn {
    height: 100%;

    .p-button {
        height: 100%;
        align-items: flex-end;
    }
}
::v-deep .p-datatable .p-datatable-tbody > tr > td,
::v-deep .p-datatable .p-datatable-thead > tr > th {
    padding: 0.7rem 1rem;
}
::v-deep .p-datatable-thead {
    .p-filter-column {
        padding: 0 !important;
    }
}
::v-deep .p-datatable-scrollable-header {
    overflow: visible;
}
</style>
